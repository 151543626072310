<style lang="scss" scoped>
.app {
  text-align: left;
  .m-input-row {
    padding: 4px 0;
  }
}
</style>

<template>
  <el-dialog
    title="手动添加商品"
    width="80%"
    :visible.sync="ishow"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    @closed="close"
  >
    <div class="app" ref="app">
      <el-form ref="form" size="mini" :model="form" label-width="100px">
        <el-form-item prop="item_id" :rules="rules.item_id" label="商品ID:">
          <el-input
            :disabled="!!id"
            v-model="form.item_id"
            @change="getTbGoods(form.item_id)"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="item_title" :rules="rules.item_title" label="标题:">
          <el-input v-model="form.item_title"> </el-input>
        </el-form-item>
        <el-form-item
          prop="short_title"
          :rules="rules.short_title"
          label="短标题:"
        >
          <el-input v-model="form.short_title"> </el-input>
        </el-form-item>
        <el-form-item prop="item_pic" :rules="rules.item_pic" label="主图:">
          <upLoadText id="up" v-model="form.item_pic"></upLoadText>
        </el-form-item>
        <el-form-item
          prop="promotion_text"
          :rules="rules.promotion_text"
          label="推广文案:"
        >
          <el-input v-model="form.promotion_text"> </el-input>
        </el-form-item>
        <el-form-item prop="price" :rules="rules.price" label="原价:">
          <el-input v-model="form.price"> </el-input>
        </el-form-item>
        <el-form-item prop="end_price" :rules="rules.end_price" label="券后价:">
          <el-input v-model="form.end_price"> </el-input>
        </el-form-item>
        <el-form-item
          prop="commission_rate"
          :rules="rules.commission_rate"
          label="佣金比例:"
        >
          <el-input v-model="form.commission_rate"> </el-input>
        </el-form-item>
        <el-form-item
          prop="shop_title"
          :rules="rules.shop_title"
          label="店铺名称:"
        >
          <el-input v-model="form.shop_title"> </el-input>
        </el-form-item>
        <el-form-item
          prop="item_topic"
          :rules="rules.item_topic"
          label="所属专题:"
        >
          <div>
            <div
              class="m-input-row"
              v-for="(item, index) in form.item_topic"
              :key="item.topic_id"
            >
              <el-select
                v-model="item.topic_id"
                size="mini"
                filterable
                placeholder="请输入关键词"
              >
                <el-option
                  v-for="item in itemTopicOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              权重：
              <el-input
                style="width: 30%"
                v-model="item.weight"
                placeholder="权重"
              ></el-input>
              <el-button
                icon="el-icon-delete"
                @click="delTopic(index)"
              ></el-button>
            </div>
            <el-button @click="addTopic">添加专题</el-button>
          </div>
        </el-form-item>
        <el-form-item
          prop="item_coupon_info"
          :rules="rules.item_coupon_info"
          label="优惠券:"
        >
          <div>
            <div
              class="m-input-row"
              v-for="(item, index) of form.item_coupon_info"
              :key="index"
            >
              <el-input
                style="width: 150px"
                v-model="item.coupon_url"
                placeholder="优惠券ID或链接"
                :disabled="!!item.id && !!id"
              ></el-input>
              --
              <el-input
                style="width: 100px"
                v-model="item.description"
                placeholder="优惠券名称"
                :disabled="!!item.id && !!id"
              ></el-input>
              满
              <el-input
                v-model="item.condition_amount"
                style="width: 100px"
                placeholder="门槛"
                :disabled="!!item.id && !!id"
              ></el-input>
              减
              <el-input
                v-model="item.coupon_amount"
                style="width: 100px"
                :disabled="!!item.id && !!id"
                placeholder="金额"
              ></el-input>
              --
              <el-date-picker
                style="width: 320px"
                type="datetimerange"
                :disabled="!!item.id && !!id"
                value-format="timestamp"
                v-model="item.term_time"
                start-placeholder="选择"
                end-placeholder="有效期"
              >
              </el-date-picker>
              --
              <el-select
                v-model="item.is_stack"
                :disabled="!!item.id && !!id"
                placeholder="是否可叠加"
                style="width: 120px"
              >
                <el-option label="可叠加" :value="1"> </el-option>
                <el-option label="不可叠加" :value="2"> </el-option>
              </el-select>
              &nbsp; &nbsp;
              <el-button
                icon="el-icon-delete"
                :disabled="!!item.id && !!id"
                @click="delCoupon(index)"
              ></el-button>
            </div>
            <el-button @click="addCoupon">添加优惠券</el-button>
          </div>
        </el-form-item>
        <el-form-item
          prop="item_activity_info"
          :rules="rules.item_activity_info"
          label="活动:"
        >
          <div>
            <div
              class="m-input-row"
              v-for="(item, index) of form.item_activity_info"
              :key="index"
            >
              <el-input
                style="width: 150px"
                v-model="item.special_text"
                placeholder="活动特色描述"
                :disabled="!!item.id && !!id"
              ></el-input>
              --
              <el-select
                v-model="item.type"
                placeholder="活动类型"
                style="width: 100px"
                :disabled="!!item.id && !!id"
              >
                <el-option label="立减" :value="1"> </el-option>
                <el-option label="折扣" :value="2"> </el-option>
                <el-option label="其他活动" :value="3"> </el-option>
              </el-select>
              --
              <el-input
                v-model="item.dec_amount"
                style="width: 100px"
                placeholder="优惠金额"
                :disabled="!!item.id && !!id"
              ></el-input>
              --
              <el-date-picker
                value-format="timestamp"
                style="width: 320px"
                type="datetimerange"
                v-model="item.term_time"
                placeholder=""
                start-placeholder="选择"
                end-placeholder="有效期"
                :disabled="!!item.id && !!id"
              >
              </el-date-picker>
              --
              <el-select
                v-model="item.is_stack"
                placeholder="是否可叠加"
                style="width: 120px"
                :disabled="!!item.id && !!id"
              >
                <el-option label="可叠加" :value="1"> </el-option>
                <el-option label="不可叠加" :value="2"> </el-option>
              </el-select>
              &nbsp; &nbsp;
              <el-button
                icon="el-icon-delete"
                :disabled="!!item.id && !!id"
                @click="delActivity(index)"
              ></el-button>
            </div>
            <el-button @click="addActivity">添加活动</el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="addGoods">添加</el-button>
          <el-button @click="ishow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>


<script>
import * as api from "@/config/api";
import { Loading } from "element-ui";
export default {
  name: "goodLibEdit",
  props: {
    id: String,
    show: Boolean,
  },

  data() {
    const checkFloatNumber = (rule, value, callback, errorMsg) => {
      const r = /^[0-9]([0-9]+)?\.?([0-9]+)?$/;
      if (value && !r.test(value)) {
        callback(new Error(errorMsg));
      } else {
        callback();
      }
    };

    const floatRule = {
      validator(rule, value, callback) {
        checkFloatNumber(rule, value, callback, "只能是数字");
      },
      trigger: "blur",
      message: "只能是数字",
    };
    return {
      ishow: true,
      showAddTopic1: false,
      itemTopicOptions: [],
      form: {
        item_id: "",
        item_title: "",
        short_title: "",
        item_pic: "",
        detail_pic: [],
        promotion_text: "",
        price: "",
        end_price: "",
        commission_rate: "",
        shop_title: "",
        item_topic: [],
        item_coupon_info: [],
        item_activity_info: [],
      },
      rules: {
        item_id: [
          {
            required: true,
            message: "请输入",
          },
        ],
        item_title: [
          {
            required: true,
            message: "请输入",
          },
        ],
        short_title: [],
        item_pic: [
          {
            required: true,
            message: "请上传",
          },
        ],
        detail_pic: [],
        promotion_text: [],
        price: [
          {
            required: true,
            message: "请输入",
          },
          floatRule,
        ],
        end_price: [
          {
            required: true,
            message: "请输入",
          },
          floatRule,
        ],
        commission_rate: [
          {
            required: true,
            message: "请输入",
          },
          floatRule,
        ],
        shop_title: [
          {
            required: true,
            message: "请输入",
          },
        ],
        item_topic: [],
        item_coupon_info: [
          {
            validator(rule, value, callback) {
              const rules = {
                coupon_url: "填写第$1条优惠券链接",
                description: "填写第$1条优惠券名称",
                condition_amount: "填写第$1条门槛",
                coupon_amount: "填写第$1条优惠金额",
                is_stack: "选择第$1条是否可叠加",
              };
              if (!value || value.length == 0) {
                callback();
                return;
              }
              const keys = Object.keys(rules);
              for (let i = 0, len = value.length; i < len; i++) {
                const item = value[i];
                for (let key of keys) {
                  if (!item[key]) {
                    callback(new Error(rules[key].replace("$1", i + 1)));
                    return;
                  }
                }
              }

              callback();
            },
            trigger: "none",
          },
        ],
        item_activity_info: [
          {
            validator(rule, value, callback) {
              const rules = {
                special_text: "填写第$1条活动特色",
                type: "选择第$1条活动类型",
                dec_amount: "填写第$1条优惠金额",
                term_time: "选择第$1条活动时间",
                is_stack: "选择第$1条是否可叠加",
              };
              if (!value || value.length == 0) {
                callback();
                return;
              }
              const keys = Object.keys(rules);
              for (let i = 0, len = value.length; i < len; i++) {
                const item = value[i];
                for (let key of keys) {
                  if (!item[key]) {
                    callback(new Error(rules[key].replace("$1", i + 1)));
                    return;
                  }
                  if (!item.id && key == "term_time" && item[key].length != 2) {
                    callback(new Error(rules[key].replace("$1", i + 1)));
                    return;
                  }
                }
              }
              callback();
            },
            trigger: "none",
          },
        ],
      },
    };
  },

  created() {
    this.$nextTick(() => {
      this.findDetails();
    });
  },

  methods: {
    close() {
      this.$emit("close");
      this.$emit("update:show", false);
    },
    findDetails() {
      if (this.id) {
        let loadingInstance = Loading.service({
          target: this.$refs.app,
          background: "rgba(0,0,0,.8)",
          spinner: "el-icon-loading",
        });
        api.getGoodLibDetail(
          {
            id: this.id,
          },
          (res) => {
            const data = res.data;

            // if (data.item_topic && data.item_topic.length > 0) {
            //     this.itemTopicOptions = data.item_topic.map((item) => {
            //         return {
            //             value: item.topic_id,
            //             label: item.topic_name,

            //         };
            //     });
            //     data.item_topic = data.item_topic.map((item) => {
            //         return {
            //             value: item.topic_id,
            //             lable: item.topic_name
            //         };
            //     });
            // }
            data.item_coupon_info.forEach((item) => {
              if (item.start_time && item.end_time) {
                item.term_time = [item.start_time * 1000, item.end_time * 1000];
              } else {
                item.term_time = [];
              }
            });
            data.item_activity_info.forEach((item) => {
              if (item.start_time && item.end_time) {
                item.term_time = [item.start_time * 1000, item.end_time * 1000];
              } else {
                item.term_time = [];
              }
            });
            this.form = data;
            loadingInstance.close();
            this.queryItemTopicOptions("");
          }
        );
      } else {
        this.queryItemTopicOptions("");
      }
    },
    queryItemTopicOptions(words) {
      api.queryItemTopicOptions(
        {
          keyword: words,
          pagesize: 100,
        },
        (res) => {
          const list = res.data.list;

          for (let i = 0, len = list.length; i < len; i++) {
            const item = list[i];

            this.itemTopicOptions.unshift({
              value: item.topic_id,
              label: item.topic_name,
            });
          }
        }
      );
    },
    addCoupon() {
      this.form.item_coupon_info.push({
        condition_amount: "",
        coupon_amount: "",
        coupon_url: "",
        description: "",
        is_stack: "",
        term_time: [],
      });
    },
    addTopic() {
      this.form.item_topic.push({
        topic_id: "",
        weight: "",
      });
    },
    delTopic(index) {
      this.form.item_topic.splice(index, 1);
      this.$nextTick(() => {
        const formEl = this.$refs.form;
        formEl.clearValidate();
      });
    },
    delCoupon(index) {
      this.form.item_coupon_info.splice(index, 1);
      this.$nextTick(() => {
        const formEl = this.$refs.form;
        formEl.clearValidate();
      });
    },

    addActivity() {
      this.form.item_activity_info.push({
        dec_amount: "",
        is_stack: "",
        special_text: "",
        status: "",
        type: "",
        term_time: [],
      });
    },
    delActivity(index) {
      this.form.item_activity_info.splice(index, 1);
      this.$nextTick(() => {
        const formEl = this.$refs.form;
        formEl.clearValidate();
      });
    },
    addGoods() {
      this.$refs.form.validate((v) => {
        if (!v) {
          return;
        }
        if (this.form.item_coupon_info.length > 0) {
          this.form.item_coupon_info.forEach((item) => {
            item.coupon_value = item.coupon_url;
            if (item.term_time && item.term_time.length > 1) {
              item.start_time = Math.floor(item.term_time[0] / 1000);
              item.end_time = Math.floor(item.term_time[1] / 1000);
            }
          });
        }
        if (this.form.item_activity_info.length > 0) {
          this.form.item_activity_info.forEach((item) => {
            if (item.term_time && item.term_time.length > 1) {
              item.start_time = Math.floor(item.term_time[0] / 1000);
              item.end_time = Math.floor(item.term_time[1] / 1000);
            }
          });
        }

        this.form.coupon_info = this.form.item_coupon_info;
        this.form.activity_info = this.form.item_activity_info;
        const callback = (res) => {
          this.ishow = false;
          this.$message({
            type: "success",
            message: res.msg,
          });
        };
        this.form.item_topic = this.form.item_topic;
        if (this.id) {
          api.updateGoodLib(this.form, callback);
        } else {
          api.addGoodLi(this.form, callback);
        }
      });
    },
    getTbGoods(id) {
      api.getGoodsInfo(
        {
          item_id: id,
          platform: 1,
        },
        (res) => {
          this.form.end_price = res.data.end_price;
          this.form.coupon_amount = res.data.discount_amount;
          this.form.item_title = res.data.item_title;
          this.form.item_pic = res.data.item_pic;
          this.form.price = res.data.price;
          this.form.user_commission = res.data.user_commission;
          this.form.commission_rate = res.data.commission_rate * 100;
          this.form.item_topic = res.data.item_topic || [];
          this.form.shop_title = res.data.shop_title;
          const coupon = res.data.coupon_info || [];
          this.form.item_coupon_info = coupon.map((item) => {
            let term_time = [];
            if (item.start_time && item.end_time) {
              term_time = [item.start_time * 1000, item.end_time * 1000];
            }
            return {
              condition_amount: item.condition_amount,
              coupon_amount: item.coupon_amount,
              coupon_url: item.coupon_id,
              description: item.description,
              is_stack: item.is_stack * 1,
              term_time: term_time,
            };
          });
        }
      );
    },
  },
};
</script>