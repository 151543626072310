<style lang="scss" scoped>
.app {
  text-align: left;
}

.goods-title-box {
  line-height: 30px;
  position: relative;
  padding-right: 160px;
  .goods-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #eee;
    box-shadow: 0px 0px 5px #999;
    vertical-align: top;
  }
  .goods-title {
    vertical-align: middle;
    padding-left: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  .goods-com-box {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    z-index: 1;
  }
}

.goods-info-box {
  font-size: 0;
  padding: 10px 0;
  .goods-info-box-item {
    display: inline-block;
    vertical-align: top;
    width: 33.333333%;
    font-size: 12px;
    .goods-info-text {
      line-height: 20px;
    }
  }
}

.goods-outer-box {
  padding-top: 10px;
  .goods-outer-title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.goods-pic-carousel {
  padding-top: 10px;
  overflow: auto;
  white-space: nowrap;
}

.goods-pic-item {
  width: 150px;
  height: 150px;
  border: 1px solid #eee;
  &:not(:first-child) {
    margin-left: 10px;
  }
}

.coupon-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 30px;
  &::after {
    content: "复制";
    color: #409eff;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.goods-item-topic-tags {
  .goods-item-topic-tag {
    margin-right: 5px;
  }
}
</style>

<template>
  <el-dialog
    title="商品详情"
    width="80%"
    :visible.sync="ishow"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @closed="close"
  >
    <div class="app" ref="app">
      <div class="goods-title-box">
        <el-image
          class="goods-image"
          :src="detail.item_pic"
          :preview-src-list="[detail.item_pic]"
        >
        </el-image>
        <span class="goods-title">{{ detail.item_title }}</span>
        <div class="goods-com-box">
          <el-button size="mini" type="primary" @click="showEditDialog = true"
            >编辑</el-button
          >
        </div>
      </div>
      <div class="goods-info-box">
        <div class="goods-info-box-item">
          <div class="goods-info-text">
            商品ID: <span>{{ detail.item_id }}</span>
          </div>
          <div class="goods-info-text">短标题: {{ detail.short_title }}</div>
          <div class="goods-info-text">店铺名称: {{ detail.shop_title }}</div>
        </div>
        <div class="goods-info-box-item">
          <div class="goods-info-text">原价: {{ detail.price }}</div>
          <div class="goods-info-text">券后价: {{ detail.end_price }}</div>
          <div class="goods-info-text">
            佣金比例: {{ detail.commission_rate }}%
          </div>
        </div>
        <div class="goods-info-box-item">
          <div class="goods-info-text">
            推荐语: {{ detail.promotion_text || "无" }}
          </div>
        </div>
      </div>

      <div
        class="goods-outer-box"
        v-if="detail.detail_pic && detail.detail_pic.length > 0"
      >
        <div class="goods-outer-title">商品图</div>
        <div class="goods-pic-carousel">
          <el-image
            v-for="item of detail.detail_pic"
            :key="item"
            class="goods-pic-item"
            :src="item"
            fit="cover"
            :preview-src-list="detail.detail_pic"
          >
          </el-image>
        </div>
      </div>

      <div class="goods-outer-box">
        <div class="goods-outer-title">所属专题</div>

        <div class="goods-item-topic-tags">
          <template v-if="detail.item_topic">
            <el-tag
              class="goods-item-topic-tag"
              :closable="true"
              type="success"
              v-for="item of detail.item_topic"
              :key="item.topic_id"
              @close="removeTopic(item)"
            >
              {{ item.topic_name }}
            </el-tag>
          </template>

          <el-popover
            placement="bottom"
            width="200"
            title="添加专题"
            trigger="manual"
            v-model="showAddTopic1"
          >
            <el-select
              value=""
              size="mini"
              @change="selectTopic"
              filterable
              placeholder="请输入关键词"
            >
              <el-option
                v-for="item in itemTopicOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              slot="reference"
              size="mini"
              icon="el-icon-circle-plus-outline"
              @click="showAddTopic1 = true"
              >添加专题</el-button
            >
          </el-popover>
        </div>
      </div>
      <div class="goods-outer-box">
        <div class="goods-outer-title">优惠券</div>
        <el-table :data="detail.item_coupon_info" border style="width: 100%">
          <el-table-column label="优惠券ID/链接" width="200">
            <template slot-scope="scope">
              <div
                class="coupon-link"
                v-clipboard:copy="scope.row.coupon_url"
                v-clipboard:success="copy"
              >
                {{ scope.row.coupon_url }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="优惠券名称" width="180">
          </el-table-column>
          <el-table-column prop="coupon_amount" label="优惠券金额" width="100">
          </el-table-column>
          <el-table-column prop="condition_amount" label="使用门槛" width="100">
          </el-table-column>
          <el-table-column label="有效期" width="200">
            <template slot-scope="scope">
              {{ scope.row.start_time | dateTime }}-{{
                scope.row.end_time | dateTime
              }}
            </template>
          </el-table-column>
          <el-table-column label="叠加" width="80">
            <template slot-scope="scope">
              {{ scope.row.is_stack != 1 ? "不" : "" }}可叠加
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              {{ ["有效", "失效"][scope.row.status - 1] }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                type="primary"
                v-if="scope.row.status == 1"
                @click="goodsLibUpdateCouponStatus(scope.row)"
                >标记失效</el-link
              ><span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="goods-outer-box">
        <div class="goods-outer-title">活动</div>
        <el-table :data="detail.item_activity_info" border style="width: 100%">
          <el-table-column label="活动特色" width="200">
            <template slot-scope="scope">
              <div
                class="coupon-link"
                v-clipboard:copy="scope.row.special_text"
                v-clipboard:success="copy"
              >
                {{ scope.row.special_text }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="活动类型" width="180">
            <template slot-scope="scope">
              {{ ["立减", "折扣", "其他活动"][scope.row.type - 1] }}
            </template>
          </el-table-column>
          <el-table-column prop="dec_amount" label="优惠金额" width="100">
          </el-table-column>
          <el-table-column label="有效期" width="200">
            <template slot-scope="scope">
              {{ scope.row.start_time | dateTime }}-{{
                scope.row.end_time | dateTime
              }}
            </template>
          </el-table-column>
          <el-table-column label="叠加" width="80">
            <template slot-scope="scope">
              {{ scope.row.is_stack != 1 ? "不" : "" }}可叠加
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              {{ ["有效", "失效"][scope.row.status - 1] }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                type="primary"
                v-if="scope.row.status == 1"
                @click="goodsLibupdateActivityStatus(scope.row)"
                >标记失效</el-link
              ><span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <edit
      v-if="showEditDialog"
      :show.sync="showEditDialog"
      :id="detail.id"
      @close="findDetails"
    ></edit>
  </el-dialog>
</template>


<script>
import * as api from "@/config/api";
import { Loading } from "element-ui";
import edit from "../Edit/index.vue";
export default {
  name: "goodLibDetails",
  components: {
    edit,
  },
  filters: {
    dateTime(val) {
      let day = new Date(Number(val) * 1000);
      if (Number(val) === 0) {
        return "";
      }
      return (
        day.getFullYear() +
        "-" +
        `${
          day.getMonth() + 1 < 10
            ? "0" + (day.getMonth() + 1)
            : day.getMonth() + 1
        }` +
        "-" +
        `${day.getDate() < 10 ? "0" + day.getDate() : day.getDate()}`
      );
    },
  },
  props: {
    pDetail: Object,
    show: Boolean,
  },
  data() {
    return {
      ishow: true,
      showAddTopic1: false,
      itemTopicOptions: [],
      detail: this.pDetail,
      showEditDialog: false,
    };
  },

  created() {
    this.$nextTick(() => {
      this.findDetails();
      this.queryItemTopicOptions("");
    });
  },

  methods: {
    close() {
      this.$emit("update:show", false);
    },
    findDetails() {
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.getGoodLibDetail(
        {
          id: this.detail.id,
        },
        (res) => {
          this.detail = res.data;
          loadingInstance.close();
        }
      );
    },
    queryItemTopicOptions(words) {
      api.queryItemTopicOptions(
        {
          keyword: words,
          pagesize: 100,
        },
        (res) => {
          const list = res.data.list;
          for (let i = 0, len = list.length; i < len; i++) {
            const item = list[i];
            const index = this.itemTopicOptions.findIndex((option) => {
              return option.value == item.topic_id;
            });
            if (index == -1) {
              this.itemTopicOptions.push({
                value: item.topic_id,
                label: item.topic_name,
              });
            }
          }
        }
      );
    },
    selectTopic(id) {
      this.showAddTopic1 = false;
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.addItemTopic(
        {
          item_id: this.detail.item_id,
          item_topic_id: id,
        },
        (res) => {
          this.findDetails();
          loadingInstance.close();
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      );
    },
    removeTopic(item) {
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.delItemTopic(
        {
          item_id: this.detail.item_id,
          item_topic_id: item.topic_id,
        },
        (res) => {
          this.findDetails();
          loadingInstance.close();
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      );
    },
    goodsLibUpdateCouponStatus(item) {
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.goodsLibUpdateCouponStatus(
        {
          id: item.id,
        },
        (res) => {
          this.findDetails();
          loadingInstance.close();
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      );
    },
    goodsLibupdateActivityStatus(item) {
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.goodsLibupdateActivityStatus(
        {
          id: item.id,
        },
        (res) => {
          this.findDetails();
          loadingInstance.close();
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      );
    },
    copy() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
};
</script>